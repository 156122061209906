<template>
  <div class="bg main">
    <!-- 简介 -->
    <div class="content syn">
      <div class="text">
        <div class="title">
          <div class="logo">
            <img src="../assets/img/about/about_logo.png" alt="logo" />
          </div>
          <div class="ctitle">南京欣网互联</div>
          <div class="stitle">网络科技有限公司</div>
        </div>
        <div class="textarea">
          <p>
            南京欣网互联网络科技有限公司成立于<span>2003</span>年，专注于互联网和系统集成领域，总部设立于南京，以南京欣网互联信息科技有限公司、南京欣网互联软件技术有限公司、南京欣网互联网络科技有限公司等六家子公司为核心，在全国设立了19个省级分支机构，目前人员规模达1000人左右。
          </p>
          <p>
            公司已成为行业中领先的服务提供商，主要为通信运营商提供电子商务综合服务，同时为其他行业用户提供技术+运营的一体化解决方案。通过多年的经营，公司业务已经覆盖三大通信运营商的17个省、直辖市区域市场。主要产品或服务分为技术开发类业务、技术和运营支撑类业务、内容销售类业务服务三类。
          </p>
          <p>
            公司历年来获得江苏省民营科技企业、江苏
            技型中小企业、江苏省规划布局内重点软件企业、南京市优秀民营企业等荣誉。同时，公司与各大运营商均建立了长期良好的合作关系，多次获得运营商颁发的“战略合作伙伴”、“优秀合作伙伴”、“诚信联盟单位”、“爱心大使”等称号，以及“锐意进取奖”、“业务创新奖”、“最佳合作奖”、“优秀合作奖”等荣誉。
          </p>
        </div>
      </div>
      <div class="ach">
        <div class="icon"></div>
        <div class="t">
          <img src="../assets/img/about/about_data_title.png" alt="" />
          <div>成就</div>
        </div>
        <div class="d">
          <div class="i" v-for="i in dataList" :key="i.num">
            <div class="bold">{{ i.num }}</div>
            <div>{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 地图分布 -->
    <div class="content map">
      <div class="img"></div>
      <div class="info">
        <div class="title">
          <div>省级分支机构分分布</div>
          <div class="b"></div>
        </div>
        <div class="list">
          <div class="item start">江苏</div>
          <div class="item" v-for="i in prvList" :key="i">{{ i }}</div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="content friend">
      <div class="banner"></div>
      <div class="list">
        <div v-for="i in 20" :key="i"></div>
      </div>
    </div>
    <!-- 山东团队 -->
    <div class="team">
      <div class="content">
        <div class="img"></div>
        <div class="info">
          <div class="title">
            <div>团队规模 <span> 200 </span>余人</div>
            <div class="b"></div>
          </div>
          <div class="group-4">
            <div class="group-item" v-for="i in t1" :key="i.id">
              <div class="top">
                {{ i.num }}
                <span>余人</span>
              </div>
              <div class="middle">{{ i.type }}</div>
              <div class="bottom">{{ i.text }}</div>
            </div>
          </div>

          <div class="group-3">
            <div class="group-item" v-for="i in t2" :key="i.id">
              <div class="top">
                {{i.num}}
                <span>余人</span>
              </div>
              <div class="middle">{{i.type}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        {
          num: 13,
          text: "项授权专利",
        },
        {
          num: 98,
          text: "项知识产权",
        },
        {
          num: 21,
          text: "项发明专利",
        },
        {
          num: 252,
          text: "项软件著作权",
        },
      ],
      prvList: [
        "山东",
        "河北",
        "北京",
        "内蒙古",
        "山西",
        "河南",
        "安徽",
        "浙江",
        "福建",
        "江西",
        "湖南",
        "广西",
        "云南",
        "四川",
        "青海",
        "甘肃",
        "宁夏",
        "新疆",
      ],
      t1: [
        {
          id: 1,
          num: 10,
          type: "管理团队",
          text: "专注于互联网、极具战略思维",
        },
        {
          id: 2,
          num: 150,
          type: "研发团队",
          text: "移动互联网、大数据技术、新技术",
        },
        {
          id: 3,
          num: 30,
          type: "运营团队",
          text: "精于数据分析、极具用户思维",
        },
        {
          id: 4,
          num: 10,
          type: "售前团队",
          text: "多行业、多领域方案及售前支撑",
        },
      ],
      t2: [
        {
          id: 1,
          num: 10,
          type: "产品经理",
        },
        {
          id: 2,
          num: 10,
          type: "UI设计",
        },
        {
          id: 3,
          num: 20,
          type: "新媒体团队",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.bg {
  overflow: hidden;
  width: 100%;
  background: url("../assets/img/about/banner_about.jpg") no-repeat;
  background-size: 2560px 900px;
  background-position: center top;
  background-color: #f5f9fc;
}

.syn {
  margin-top: 640px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .text {
    width: 840px;
    background: #fff;

    .title {
      height: 118px;
      display: flex;
      margin-left: 36px;
      align-content: flex-end;
      padding-top: 26px;
      .logo {
        z-index: 2;
        position: relative;
        top: -102px;
        width: 220px;
        height: 220px;
        background: rgba(0, 0, 0, 0);
        text-align: center;

        img {
          margin: auto;
          border-radius: 50%;
        }
      }
      .ctitle {
        z-index: 1;
        width: 293px;
        height: 64px;
        position: relative;
        left: -25px;
        padding-right: 17px;
        background-color: #e8caa3;
        border-radius: 0px 3px 3px;
        font-size: 40px;
        font-weight: bold;
        color: #3d3428;
        text-align: right;
      }
      .stitle {
        height: 64px;
        line-height: 54px;
        font-size: 30px;
        color: #313131;
        margin-left: -13px;
      }
    }
    .textarea {
      padding: 0 60px;
      font-size: 20px;
      line-height: 40px;
      color: #515151;
      padding-bottom: 40px;
      p {
        text-indent: 2em;
        margin-top: 45px;
        span {
          color: #1b65fb;
          font-weight: bold;
        }
      }
    }
  }
  .ach {
    width: 380px;
    height: 490px;
    background: url("../assets/img/about/about_data_bg.jpg") no-repeat 100%;
    border-radius: 3px;
    position: relative;
    .icon {
      position: absolute;
      width: 84px;
      height: 71px;
      background: url("../assets/img/about/about_icon.png") no-repeat 100%;
      bottom: 0px;
      left: -77px;
    }
    .t {
      width: 372px;
      height: 54px;
      margin-top: 30px;
      font-size: 20px;
      line-height: 64px;
      color: #095088;
      display: flex;
      justify-content: space-between;
    }
    .d {
      display: grid;
      grid-template-columns: repeat(2, 130px);
      grid-template-rows: repeat(2, 130px);
      grid-gap: 50px 40px;
      margin: 47px 35px 0 35px;
      .i {
        background: url("../assets/img/about/about_round.png");
        font-size: 14px;
        color: #0a5088;
        text-align: center;
        .bold {
          font-weight: bold;
          color: #095088;
          font-size: 40px;
          margin-top: 26px;
        }
      }
    }
  }
}
.map {
  display: flex;
  background-color: #fff;
  padding-bottom: 20px;
  .img {
    width: 884px;
    height: 731px;
    background: url("../assets/img/about/about_map.png");
    margin-top: 80px;
    margin-left: 33px;
  }
  .info {
    width: 330px;
    margin-top: 48px;
    .title {
      font-size: 30px;
      line-height: 40px;
      color: #212121;
      font-weight: bold;
      text-align: center;
      .b {
        z-index: 1;
        width: 330px;
        height: 20px;
        background-color: #e8caa3;
        border-radius: 10px 0px 0px 10px;
        position: relative;
        top: -17px;
      }
      div {
        z-index: 2;
        position: relative;
      }
    }
    .item {
      width: 131px;
      height: 35px;
      border-bottom: 1px solid #f1f1f1;
      font-size: 16px;
      padding-left: 14px;
      margin-left: 70px;
      line-height: 35px;
      color: #515151;
      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #2397f3;
        border-radius: 50%;
        margin-right: 34px;
      }
    }
    .start {
      width: 136px;
      padding-left: 9px;
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../assets/img/about/about_star.png");
        margin-right: 29px;
        vertical-align: -5px;
      }
    }
  }
}
.friend {
  background-color: #fff;
  .banner {
    width: 1260px;
    height: 260px;
    background: url("../assets/img/about/about_partner_title.png") no-repeat
      100%;
    border-radius: 3px 3px 0px 0px;
  }
  .list {
    display: grid;
    grid-template-columns: repeat(5, 224px);
    grid-template-rows: repeat(4, 70px);
    grid-gap: 25px 20px;
    padding: 20px 30px;
    div {
      border-radius: 3px;
      border: solid 1px #f1f1f1;
      @for $i1 from 1 through 20 {
        &:nth-child(#{$i1}) {
          background: url("../assets/img/about/about_partner_logo_#{$i1}.jpg")
            no-repeat
            100%;
        }
      }
    }
  }
}
.team {
  background-color: #fff;
  .content {
    display: flex;
    justify-content: space-between;
  }
  .img {
    width: 610px;
    height: 700px;
    background: url("../assets/img/about/about_shandong_img.jpg");
  }
  .info {
    width: 608px;
    .title {
      text-align: right;
      font-size: 30px;
      color: #212121;
      position: relative;
      font-weight: bold;
      div {
        position: relative;
        z-index: 2;
        span {
          font-size: 36px;
          color: #1b65fb;
        }
      }
      .b {
        z-index: 1;
        width: 290px;
        height: 20px;
        background-color: #e8caa3;
        border-radius: 10px 0px 0px 10px;
        position: absolute;
        top: 25px;
        right: 0;
      }
    }
    .group-4 {
      margin: 90px auto 60px auto;
      width: 570px;
      display: grid;
      grid-template-columns: repeat(2, 265px);
      grid-template-rows: repeat(2, 150px);
      grid-gap: 40px;
    }

    .group-3 {
      margin: 0 auto;
      width: 570px;
      display: grid;
      grid-template-columns: repeat(3, 130px);
      grid-template-rows: repeat(1, 150px);
      grid-gap: 90px;
    }
    .group-item {
      text-align: center;
      .top {
        font-size: 60px;
        color: #2397f3;
        font-weight: bold;
        position: relative;
        span {
          font-size: 12px;
          position: absolute;
          bottom: 12px;
        }
      }
      .middle {
        margin: 0 auto;
        width: 130px;
        height: 36px;
        background-color: #e6f4ff;
        border-radius: 3px;
        text-align: center;
        line-height: 36px;
        font-size: 20px;
        color: #515151;
      }
      .bottom {
        font-size: 14px;
        color: #cba574;
      }
    }
  }
}
</style>